import React from "react";

export default function ContractPrintCondition() {
  return (
    <div id="contractprintcondition" className="my-3 text-[10px]">
      <p className={"text-center"}>
        <u>เงื่อนไขของสัญญาบริการพื้นที่แสดงสินค้า</u>
      </p>
      <div id="context">
        <p>
          <u>สินค้าที่นำมาแสดงภายในงาน</u>
          <br />
          1.สินค้าที่ผู้แสดงสินค้านำมาแสดงหรือ จำหน่ายภายในงาน
          ต้องไม่เป็นสินค้าต้องห้ามตามกฎหมาย ไม่ขัดต่อขนบธรรมเนียม
          หรือประเพณีอันดีงามของบ้านเมือง
          <br />
          2.สินค้าที่ได้ขายไปในงานนี้
          ผู้แสดงสินค้าทราบดีว่าหน้าที่ในการเสียภาษีตามกฎหมายเป็นหน้าที่ของผู้แสดงสินค้าที่ได้ขายสินค้านั้นๆ
          รวมไปถึงหน้าที่และความรับผิดชอบของผู้ขายที่มีต่อผู้ซื้อ
          ซึ่งผู้จัดงานไม่มี ส่วนเกี่ยวข้อง รู้เห็นกับการขายสินค้าใดๆ ภายในงาน
          <br />
          3.สินค้าที่ผู้แสดงสินค้านำมาแสดงหรือจำหน่ายภายในงาน
          จะต้องเป็นสินค้าที่ระบุไว้ในรายละเอียดของสินค้าตามตารางข้างต้น
          หากผู้แสดงสินค้านำสินค้าอื่นใดที่ไม่ตรงตามที่ระบุในรายละเอียดของสินค้า
          ตามตารางข้างต้น ผู้จัดงานมีสิทธิบอกเลิกสัญญาได้ทันที
          โดยทั้งนี้ผู้แสดงสินค้ายังคงต้องรับผิดชอบค่าบริการตามสัญญาเต็มจำนวน
          และผู้แสดงสินค้าไม่สามารถที่จะเรียกร้องค่าเสียหายใดๆ
          ต่อผู้จัดงานทั้งสิ้น
        </p>
        <p>
          <u>ค่าบริการพื้นที่แสดงสินค้า</u>
          <br />
          4.ผู้แสดงสินค้าจะต้องชำระค่าบริการพื้นที่แสดงสินค้าให้ครบถ้วนเรียบร้อยตามตารางการชำระเงินข้างต้น
          หากผู้แสดงสินค้าไม่ได้ชำระเงินตรงตามที่ระบุไว้
          หรือไม่ได้สั่งจ่ายเช็คภายในวันที่กำหนดไว้ตามตารางข้างต้นผู้จัดงานมีสิทธิยกเลิกสัญญานี้
          โดยมิพักต้องบอกกล่าวก่อนและผู้แสดงสินค้าจะเรียกร้องค่าเสียหายใด ๆ
          จากผู้จัดงานไม่ได้ทุกกรณี
          <br />
          5.ในกรณีที่ผู้แสดงสินค้ายังค้างชำระค่าบริการพื้นที่แสดงสินค้ากับผู้จัดงาน
          เมื่อสิ้นสุดการแสดงสินค้าตามสัญญานี้แล้ว
          ผู้จัดงานสามารถใช้สิทธิยึดหน่วงทรัพย์สินใด ๆ
          ที่ผู้แสดงสินค้านำเข้ามาในพื้นที่แสดงสินค้า
          และหากผู้แสดงสินค้าไม่ยอมชำระค่าบริการพื้นที่ที่ค้างชำระให้เสร็จสิ้นภายใน
          15
          วันนับแต่การแสดงสินค้าสิ้นสุดลงผู้จัดงานมีสิทธิจะนำทรัพย์สินที่ยึดหน่วงไว้ดังกล่าวข้างต้นออกขาย
          หรือขายทอดตลาด
          เพื่อชำระหนี้ค่าบริการพื้นที่ที่ค้างชำระรวมทั้งค่าใช้จ่ายต่าง ๆ
          เพื่อการดังกล่าว
          ด้วยหากมีเงินคงเหลือเท่าใดให้ผู้จัดงานส่งคืนให้แก่ผู้แสดงสินค้า
          ถ้าการขายหรือขายทอดตลาดดังกล่าวได้เงินไม่เพียงพอแก่การชำระค่าบริการพื้นที่
          รวมทั้งค่าใช้จ่ายนั้นผู้แสดงสินค้ายังคงต้องรับผิดชอบในส่วนที่ขาดจำนวนพร้อมทั้งดอกเบี้ยใน
          อัตราร้อยละ 15 ต่อปี ของจำนวนเงินที่ยังคงค้างชำระนับตั้งแต่วันที่ขาย
          หรือขายทอดตลาดเป็นต้นไป จนกว่าจะชำระให้แก่ผู้จัดงานจนแล้วเสร็จ
        </p>
        <p>
          ในการขาย หรือขายทอดตลาดดังที่กล่าวข้างต้น ผู้จัดงานจะเป็นผู้กำหนดวัน
          เวลา สถานที่ และเงื่อนไขในการขาย
          โดยจะแจ้งให้ผู้แสดงสินค้าทราบล่วงหน้าก่อน หรือไม่ก็ได้
          ทั้งนี้โดยถือว่าผู้แสดงสินค้าได้ให้ความยินยอมไว้ล่วงหน้า และไม่โต้แย้ง
          หรือเรียกร้องค่าเสียหายใดๆ แก่ผู้จัดงาน
        </p>
        <p>
          อนึ่งในกรณีที่ผู้แสดงสินค้าชำระค่าบริการพื้นที่แสดงสินค้าด้วยเช็ค
          ผู้จัดงานยังคงมีสิทธิยึดหน่วงทรัพย์สินและสินค้าของผู้แสดงสินค้าไว้จนกว่าจะได้รับการใช้เงินตามเช็คโดยครบถ้วนแล้ว
        </p>
        <p>
          <u>การใช้พื้นที่แสดงสินค้าและการแสดงสินค้า</u>
          <br />
          6.ผู้แสดงสินค้าจะต้องปฏิบัติตามกฎระเบียบการแสดงสินค้า
          ตามที่ผู้จัดงานได้วางระเบียบไว้
          และยังต้องปฏิบัติตามกฎระเบียบการใช้สถานที่แสดงสินค้าที่เจ้าของ
          หรือผู้บริหารศูนย์แสดงสินค้านั้นๆได้กำหนดขึ้นอย่างเคร่งครัด
          <br />
          อนึ่งสำหรับกฎระเบียบการแสดงสินค้ารวมถึงกฎระเบียบการใช้สถานที่ของศูนย์แสดงสินค้าตามวรรคต้น
          ให้ถือว่าผู้แสดงสินค้าได้รับทราบเป็นอย่างดีแล้วในวันทำสัญญานี้
          <br />
          หากปรากฏว่าผู้แสดงสินค้าฝ่าฝืนกฎระเบียบต่างๆ ดังกล่าวตามวรรคต้น
          และผู้จัดงานได้แจ้งเตือนเป็นลายลักษณ์อักษรให้ผู้แสดงสินค้าหยุดกระทำการฝ่าฝืนระเบียบนั้นแล้ว
          แต่ไม่เป็นผลผู้จัดงานมีสิทธิยกเลิกสัญญา พร้อมทั้งขนย้าย
          ทรัพย์สินและบริวารของผู้แสดงสินค้าออกไปจากบริเวณงานได้ทันที
          ทั้งนี้ผู้แสดงสินค้าจะไม่เรียกร้องค่าเสียหาย หรือค่าอื่นใด
          ต่อผู้จัดงานทั้งสิ้น
          ทั้งนี้ผู้แสดงสินค้ายังคงต้องมีหน้าที่ในการชำระค่าพื้นที่ตามสัญญาเต็มจำนวน
          พร้อมทั้งยังคงต้อง รับผิดชอบค่าใช้จ่าย พร้อมทั้งค่าเสียหายใดๆ
          ที่เกิดขึ้นจากกรณีดังกล่าวนี้
          <br />
          7.ผู้แสดงสินค้าจะต้องใช้ความระมัดระวังในการใช้พื้นที่แสดงสินค้า
          หากปรากฏความเสียหายใดๆ ไม่ว่าต่อทรัพย์สิน ชีวิต และร่างกาย
          ทั้งของผู้แสดงสินค้าเอง หรือบุคคลอื่นใด อันเกิดจากการกระทำ
          หรือความประมาทเลินเล่อของผู้แสดงสินค้า
          ผู้แสดงสินค้าจะต้องรับผิดชอบต่อความเสียหายนั้นๆ ทั้งทางแพ่งและทางอาญา
          <br />
          8.กรณีผู้แสดงสินค้ากระทำการใดๆ อันไม่เหมาะสมภายในบริเวณงาน
          ผู้จัดงานมีสิทธิระงับการแสดง หรือการขายสินค้าได้
          จนกว่าผู้แสดงสินค้าจะได้แก้ไขเหตุอันไม่เหมาะสมนั้นๆ
          ให้เป็นที่เรียบร้อยก่อน
          <br />
          9.เพื่อไม่ให้ส่งผลกระทบต่อผู้แสดงสินค้ารายอื่น
          ผู้แสดงสินค้าต้องนำสินค้ามาแสดงในพื้นที่ที่รับบริการตามรายละเอียดของประเภทสินค้าที่ได้ระบุไว้ข้างต้น
          และตามจำนวนวันที่มีการจัดแสดงสินค้าตามที่ระบุไว้โดยการแสดงสินค้าเช่นว่านี้จักต้อง
          เป็นการแสดงสินค้าตามพฤติการณ์ที่วิญญูชนทั่วไปพึงกระทำ
          หากฝ่าฝืนมินำมาแสดง
          หรือจัดแสดงสินค้าไม่ครบตามจำนวนวันที่กำหนดให้มีการจัดแสดงสินค้า
          ผู้แสดงสินค้าจะต้องเสียค่าปรับเป็นเงิน 1 เท่า ของราคาสุทธิตามสัญญานี้
          รวมทั้งค่าเสียหายใดๆ อันเกิดขึ้นจากการนี้ด้วย
          <br />
          10.ผู้แสดงสินค้าจะต้องรับผิดชอบในความเสียหายอันเกิดจากการกระทำของพนักงานหรือบริวารของผู้แสดงสินค้า
          ที่ได้กระทำให้เกิดความเสียหายต่อสถานที่หรือทรัพย์สินของบุคคลอื่นที่ได้นำมาแสดงภายในบริเวณงาน
          <br />
          11.เพื่อภาพลักษณ์ของการจัดงานแสดงสินค้า
          ผู้จัดงานขอสงวนสิทธิในการปรับเปลี่ยนแผนผังพื้นที่แสดงสินค้า
          โดยไม่ต้องแจ้งให้ทราบล่วงหน้า อนึ่งผู้แสดงสินค้าทราบ
          และยอมรับดีว่าในการปรับเปลี่ยนผังโดยผู้จัดงานอาจมีความจำเป็นในการปรับ
          ย้าย
          พื้นที่แสดงสินค้าของผู้แสดงสินค้าไปยังตำแหน่งใหม่ในจำนวนพื้นที่แสดงสินค้าเท่าเดิมตามสัญญา
          ซึ่งผู้แสดงสินค้ายินยอมรับในพื้นที่ และผังที่ปรับใหม่
          และจะไม่นำการปรับย้ายพื้นที่ดังกล่าวยกเป็นข้ออ้าง หรือข้อต่อสู้ใดๆ
          ต่อผู้จัดงานไม่ว่า กรณี ใดๆ ทั้งสิ้น
          ทั้งนี้ผู้แสดงสินค้ายังคงมีหน้าที่ตามสัญญานี้ทุกประการ
          และจะไม่เรียกร้องค่าเสียหายใดๆ ต่อผู้จัดงานจากกรณีนี้ทั้งสิ้น
          <br />
          12.ผู้แสดงสินค้าจะต้องจัดวางสินค้า หรือจัดกิจกรรมใด ๆ
          ให้อยู่ภายในขอบเขตของพื้นที่แสดงสินค้าที่ผู้จัดงานกำหนดให้เท่านั้น
          หากผู้แสดงสินค้าจัดวางสินค้า หรือจัดกิจกรรมใดๆ
          ล้ำขอบเขตพื้นที่แสดงสินค้าที่ผู้จัดงานกำหนดให้ ผู้จัดงานขอสงวน สิทธิ์
          ในการหยุดการจำหน่ายสินค้า หรือดำเนินกิจกรรมใดๆ ของผู้แสดงสินค้าทันที
          ทั้งนี้จนกว่าผู้แสดงสินค้าจะได้ดำเนินการแก้ไขการกระทำดังกล่าวเรียบร้อยแล้ว
          <br />
          13.ผู้แสดงสินค้าทราบดีว่า
          ผู้จัดงานได้ใช้บริการผู้รับเหมาติดตั้งไฟฟ้าอย่างเป็นทางการที่สถานที่จัดแสดงสินค้านั้นๆ
          เป็นผู้กำหนดให้เป็นผู้ดูแลติดตั้งไฟฟ้า
          และแสงสว่างภายในงานแสดงสินค้าทั้งหมดแต่เพียงผู้เดียว
          ทั้งนี้ไม่ว่าจะเป็นไฟฟ้าที่ผู้จัดงานมีให้
          รวมทั้งไฟฟ้าที่ผู้แสดงสินค้าได้สั่งเพิ่มเติมจากผู้รับเหมาติดตั้งไฟฟ้าอย่างเป็นทางการด้วย
          ดังนั้นหากเกิดความเสียหายใดๆ ไม่ว่าต่อทรัพย์สิน ชีวิต และร่างกาย
          ทั้งของผู้แสดงสินค้าเอง หรือบุคคลอื่นใด อันเกิดจากไฟฟ้า และแสงสว่าง
          ผู้จัดงาน จะไม่รับผิดชอบต่อความเสียหายที่เกิดขึ้นทั้งสิ้น
          ทั้งนี้ไม่ว่ากรณีใดๆ
          <br />
          อนึ่งหากผู้แสดงสินค้าต้องการติดตั้งไฟฟ้า และแสงสว่าง
          หรือกระทำการอื่นใดอันเกี่ยวกับไฟฟ้าต้องติดต่อ
          และดำเนินการโดยผู้รับเหมาติดตั้งไฟฟ้าอย่างเป็นทางการเท่านั้น
          ห้ามผู้แสดงสินค้ากระทำการต่อสายไฟ หรือ ติดตั้งอุปกรณ์ ไฟฟ้า
          หรือกระทำการใดเกี่ยวกับไฟฟ้าด้วยตนเอง หากเกิดความเสียหาย
          เนื่องจากการกระทำดังกล่าว
          ผู้แสดงสินค้าจะต้องรับผิดชอบต่อความเสียหายนั้นด้วยตนเอง
          <br />
          14.ห้ามมิให้ผู้แสดงสินค้า ลูกจ้าง บริวาร
          หรือบุคคลอื่นที่ได้รับมอบหมายจากผู้แสดงสินค้าทำการแจกใบปลิว บัตรเชิญ
          ในบริเวณทางเดิน ทางเข้า - ออก หรือบริเวณที่สาธารณะอื่นเป็นเด็ดขาด
          หากฝ่าฝืนผู้แสดงสินค้าจะต้องเสียค่าปรับเป็นเงิน 20,000 บาท
          (สองหมื่นบาทถ้วน) ต่อครั้ง
          <br />
          15.หากผู้แสดงสินค้ากระทำการใดๆ
          อันเป็นการฝ่าฝืนเงื่อนไขการใช้พื้นที่แสดงสินค้าและการแสดงสินค้าข้อใดข้อหนึ่งตามที่ระบุใน
          ข้อ 6. ถึง ข้อ 14. ของสัญญาบริการพื้นที่แสดงสินค้า
          ผู้จัดงานขอสงวนสิทธิ์ในการที่จะยุติการขาย หรือยุติการทำ
          กิจกรรมอันเป็นการฝ่าฝืนดังกล่าว
          และปิดคูหาแสดงสินค้าของผู้แสดงสินค้าได้ทันที
          ทั้งนี้จนกว่าผู้แสดงสินค้าจะได้ดำเนินการแก้ไขการกระทำดังกล่าวและปฏิบัติตามเงื่อนไขของสัญญาบริการพื้นที่แสดงสินค้าเป็นที่เรียบร้อยแล้ว
        </p>

        <p>
          <u>สินค้าที่อยู่ภายในบริเวณงานแสดงสินค้า</u>
          <br />
          16.ผู้แสดงสินค้าทราบ
          และยอมรับดีว่าผู้จัดงานเป็นเพียงผู้ไห้บริการพื้นที่แสดงสินค้าเท่านั้นโดยการให้บริการพื้นที่ดังกล่าวมิได้รวมถึงการรับฝาก
          หรือรับผิดชอบต่อการสูญหาย หรือเสียหายของสินค้าที่ได้นำมาแสดงภายในงาน
          ดังนั้นกรณีผู้แสดงสินค้า มีความประสงค์ที่จะฝากสินค้า
          หรือขอรับการดูแลความปลอดภัย หรือการประกันการสูญหาย
          หรือเสียหายของสินค้าที่นำมาแสดงภายในงานผู้แสดงสินค้าจะต้องว่าจ้างพนักงานรักษาความปลอดภัยเพิ่มเติมเป็นพิเศษ
          โดยค่าใช้จ่ายเป็นของผู้แสดงสินค้าเองทั้งสิ้น
          <br />
          17.ผู้จัดงานได้จัดทำประกันอัคคีภัยให้กับสินค้าที่นำมาแสดงภายในงานกับบริษัทประกันภัยโดยได้ทำประกันอัคคีภัยไว้ไนวงเงินเอาประกัน
          50,000 บาท (ห้าหมื่นบาทถ้วน) ต่อ 1 บูธ แสดงสินค้า (9 ตารางเมตร)
          ดังนั้นหากผู้แสดงสินค้ามีความประสงค์ที่จะเอาประกันสินค้า
          ในวงเงินเพิ่มเติมมากกว่านี้ผู้แสดงสินค้าจะต้องแจ้งเป็นลายลักษณ์อักษรเพื่อให้ผู้จัดงานแจ้งทำวงเงินประกันภัยเพิ่มเติมให้แก่ผู้แสดงสินค้าเป็นพิเศษโดยเฉพาะทั้งนี้ผู้แสดงสินค้าต้องเป็นผู้รับผิดชอบชำระเบี้ยประกันในส่วนที่เพิ่มเติมวงเงินเอง
        </p>

        <p>
          <u>การบอกเลิกสัญญาบริการพื้นที่แสดงสินค้า</u>
          <br />
          18.ในกรณีที่ผู้แสดงสินค้าประสงค์จะบอกเลิกสัญญาบริการพื้นที่แสดงสินค้า
          ผู้แสดงสินค้าจะต้องบอกเลิกสัญญาบริการพื้นที่แสดงสินค้าเป็นลายลักษณ์อักษรมายังผู้จัดงาน
          และต้องได้รับความยินยอมจากผู้จัดงานเป็นลายลักษณ์อักษรเท่านั้น
          พร้อมทั้งชำระค่าปรับให้แก่ผู้จัดงานตามอัตราส่วนของระยะเวลาในการบอกเลิกสัญญาตามที่กำหนดไว้ในสัญญาข้อ
          19
          <br />
          19.เนื่องจากการจัดงานแสดงสินค้าในแต่ละงาน
          ผู้จัดงานมีความจำเป็นต้องจัดเตรียมความพร้อมในทุกด้านของงานแสดงสินค้า
          ไม่ว่าจะเป็นสถานที่ การประชาสัมพันธ์ และอื่นๆ
          ซึ่งผู้จัดงานจำเป็นต้องผูกพันตนกับค่าใช้จ่ายต่างๆ ที่ได้กระทำไว้กับ
          บุคคลภายนอก ดังนั้น หากผู้แสดงสินค้ามีความประสงค์จะยกเลิกสัญญาฉบับนี้
          ผู้แสดงสินค้าจะต้องชำระค่าปรับให้แก่ผู้จัดงานดังต่อไปนี้
          <br />
          19.1 บอกเลิกสัญญาก่อน 60 วัน นับถึงวันเริ่มต้นงานแสดงสินค้า
          ผู้แสดงสินค้าจะต้องเสีย ค่าปรับเป็นเงิน 30%
          ของราคาค่าบริการพื้นที่สุทธิตามสัญญา
          <br />
          19.2 บอกเลิกสัญญาก่อน 30 วัน นับถึงวันเริ่มต้นงานแสดงสินค้า
          ผู้แสดงสินค้าจะต้องเสีย ค่าปรับเป็นเงิน 50%
          ของราคาค่าบริการพื้นที่สุทธิตามสัญญา
          <br />
          19.3 บอกเลิกสัญญาก่อน 15 วัน นับถึงวันเริ่มต้นงานแสดงสินค้า
          ผู้แสดงสินค้าจะต้องเสีย ค่าปรับเป็นเงิน 100%
          ของราคาค่าบริการพื้นที่สุทธิตามสัญญา
          <br />
          19.4 บอกเลิกสัญญาในระหว่างที่มีการจัดแสดงสินค้า
          หรือนำสินค้ามาแสดงไม่ครบตามวันที่กำหนดในสัญญา
          ผู้แสดงสินค้ายังคงมีหน้าที่ต้องชำระค่าบริการพื้นที่แสดงสินค้าเต็มตามจำนวนที่ระบุไว้ในสัญญา
          <br />
          20.หากมีความจำเป็นใดๆ ผู้จัดงานมีสิทธิในการบอกเลิก
          หรือยกเลิกสัญญานี้ต่อผู้แสดงสินค้าได้
          โดยที่ผู้แสดงสินค้าจะไม่เรียกร้องค่าเสียหายใดๆ
          ในกรณีที่ผู้แสดงสินค้าได้ชำระเงินมาแล้วตามสัญญานี้
          ผู้จัดงานจะคืนเงินให้แก่ผู้แสดงสินค้า ตามจำนวน
          ที่ได้รับชำระมาแล้วเท่านั้น
          <br />
          21.ผู้จัดงานมีสิทธิเลื่อนวัน เวลา ของการจัดงานแสดงสินค้าได้
          โดยใช้สถานที่จัดงานเดิม ในกรณีที่ผู้จัดงานต้องเลื่อนวัน
          เวลาจัดงานดังกล่าว ผู้แสดงสินค้าจะได้รับพื้นที่ตามเดิม
          โดยผู้แสดงสินค้ายังคงต้องมีหน้าที่ผูกพันตามสัญญานี้ทุกประการ
          และจะไม่ยกเหตุผลดังกล่าวมาเป็นข้ออ้างในการเรียกร้องค่าเสียหาย
          หรือค่าอื่นใดจากผู้จัดงานทั้งสิ้น
          <br />
          22.ผู้จัดงานมีสิทธิเลื่อนวันเวลา และเปลี่ยนสถานที่ในการจัดงานได้
          ในการนี้ผู้จัดงานจะจัดหาวันเวลาสถานที่ใหม่ให้แก่ผู้แสดงสินค้าโดยผู้แสดงสินค้าจะได้รับพื้นที่แสดงสินค้าในสถานที่ใหม่นี้ตามขนาดเท่าเดิมตามที่ระบุไว้ในสัญญา
          <br />
          23.หากมีเหตุการณ์ดังต่อไปนี้ (ก) อัคคีภัย น้ำท่วม
          หรือภัยลมฟ้าอากาศอื่นๆ (ข) การแข็งข้อ การกบฏ การจลาจล การยึดอำนาจ
          การก่อความวุ่นวายทางการเมือง การเปลี่ยนแปลงกฎหมาย การเปลี่ยนกฎระเบียบ
          หรือกฎหมายของทางราชการ การกระท้าของขบวนการโจรก่อการร้าย
          การกระท้าของผู้ก่อการร้าย คอมมิวนิสต์การปฏิวัติ การรัฐประหาร
          การประกาศกฎอัยการศึก หรือเหตุสุดวิสัยอื่นๆ ซึ่งในกรณีต่างๆ
          ที่กล่าวมาข้างต้นนี้เป็นผลโดยตรง หรือโดยอ้อมที่ทำให้งานแสดงสินค้า
          ไม่สามารถจัดขึ้นได้แต่เนื่องจากผู้จัดงานได้จัดเตรียมการต่างๆ
          ไว้ล่วงหน้า และได้ออกค่าใช้จ่ายไปก่อนแล้ว
          ดังนั้นผู้แสดงสินค้ายังคงมีหน้าที่ชำระค่าบริการพื้นที่
          ให้แก่ผู้จัดงานโดยตกลงยึดตามหลักเกณฑ์ดังต่อไปนี้
          <br />
          23.1 เมื่อประกาศเลิกการจัดงานก่อนหน้าวันเปิดแสดงภายใน 20 วัน
          ผู้แสดงสินค้าจะต้องชำระค่าบริการในวงเงิน 50%
          <br />
          23.2 เมื่อประกาศเลิกการจัดงานก่อนหน้าวันเปิดแสดงภายใน 7 วัน
          ผู้แสดงสินค้าจะต้องชำระค่าบริการในวงเงิน 80%
          <br />
          23.3 เมื่อประกาศเลิกการจัดงานก่อนหน้าวันเปิดแสดงภายใน 2 วัน
          ผู้แสดงสินค้าจะต้องชำระค่าบริการในวงเงิน 100%
        </p>

        <p>
          อนึ่งหากมีเหตุใดๆ
          อันท้าให้งานแสดงสินค้าต้องยุติก่อนกำหนดหลังจากที่ได้เปิดแสดงไปแล้ว
          ผู้แสดงสินค้ายังคงต้องรับผิดชอบชำระค่าบริการพื้นที่แสดงสินค้าให้แก่ผู้จัดงานเต็มจำนวน
          <br />
          24.ในระหว่างที่มีการจัดการแสดงสินค้า
          หากผู้แสดงสินค้ารายใดได้จัดแสดงสินค้า
          อันก่อให้เกิดความเดือนร้อนรำคาญต่อผู้แสดงสินค้ารายอื่น
          ผู้จัดงานได้พิจารณาแล้วเห็นว่าควรงดเว้นการกระทำดังกล่าว
          โดยผู้จัดงานได้แจ้งให้ผู้แสดงสินค้างดเว้นการกระทำดังกล่าวเป็นลายลักษณ์อักษรแล้ว
          แต่ผู้แสดงสินค้ายังคงเพิกเฉย ผู้จัดงานมีสิทธิบอกเลิก
          หรือยกเลิกสัญญาต่อผู้แสดงสินค้าได้
          โดยผู้แสดงสินค้าจะไม่เรียกร้องค่าเสียหายใดๆ ต่อผู้จัดงานทั้งสิ้น
          และผู้แสดงสินค้ายังคงมีหน้าที่ต้อง
          ชำระค่าบริการพื้นที่แสดงสินค้าให้แก่ผู้จัดงานเต็มตามจำนวน
        </p>

        <p>
          <u>การโอนสิทธิในการใช้บริการพื้นที่แสดงสินค้า</u>
          <br />
          25.ผู้แสดงสินค้าไม่มีสิทธิ์โอนสิทธิการรับบริการพื้นแสดงสินค้าให้แก่บุคคลอื่น
          หรือนำพื้นที่แสดงสินค้าให้บุคคลอื่นเช่าช่วงโดยเด็ดขาด
          เว้นแต่ได้รับความยินยอมจากผู้จัดงานเป็นลายลักษณ์อักษร
          เฉพาะในกรณีที่ผู้แสดงสินค้าจะโอนสิทธิการรับ
          บริการพื้นที่แสดงสินค้าให้กับบุคคลรายอื่น
          ในการนี้ผู้แสดงสินค้าจะต้องเสียค่าธรรมเนียมในการเปลี่ยนแปลงสัญญาบริการพื้นที่แสดงสินค้าในอัตรา
          20% ของอัตราค่าบริการพื้นที่สุทธิตามสัญญา ทั้งนี้
          ผู้แสดงสินค้าทำการฝ่าฝืนผู้จัดงานขอ
          สงวนสิทธิ์ในการปิดคูหาหากมีกรณีดังกล่าวเกิดขึ้น
        </p>

        <p>
          <u>การรื้อถอนและการขนย้ายสินค้า</u>
          <br />
          26.เมื่อสิ้นสุดระยะเวลาของงานแสดงสินค้า
          ผู้แสดงสินค้าจะต้องขนย้ายทรัพย์สินพร้อมทั้งบริวารออกจากพื้นที่บริการ
          ตามเวลาในตารางรื้อถอนคูหาแสดงสินค้าข้างต้นหากผู้แสดงสินค้าไม่ทำการขนย้าย
          หรือขนย้ายไม่เสร็จสิ้นภายในระยะเวลาที่กำหนดไว้
          ผู้แสดงสินค้ายินยอมให้ผู้จัดงานดำเนินการขนย้าย
          หรือว่าจ้างบุคคลภายนอกมาขนย้ายทรัพย์สินดังกล่าว
          ออกนอกบริเวณสถานที่แสดงสินค้าได้
          ทั้งนี้ผู้แสดงสินค้าจะไม่เรียกร้องค่าเสียหาย
          หรือค่าอื่นใดจากผู้จัดงานทั้งสิ้น อนึ่งหากผู้จัดงาน
          ต้องมีค่าใช้จ่ายจากการขนย้าย
          หรือค่าใช้จ่ายในการว่าจ้างบุคคลภายนอกไปเป็นจำนวนเท่าใด
          ผู้แสดงสินค้าจะต้องชดใช้เงินให้แก่ผู้จัดงานจนเต็มจำนวน
          ทั้งนี้ยังคงต้องเสียค่าปรับกรณีขนย้ายล่าช้าให้แก่ผู้จัดงานเป็นเงินชั่วโมงละ
          30,000 บาท ( สามหมื่นบาทถ้วน )
          นับตั้งแต่เวลาที่ให้ขนย้ายคูหาแสดงสินค้าได้สิ้นสุดลง
        </p>
        <p>
          27.ผู้แสดงสินค้ายอมรับเงื่อนไข และยินยอมให้ผู้จัดงาน
          เปิดเผยข้อมูลส่วนบุคคลของผู้แสดงสินค้า เพื่อเก็บรวบรวม ใช้
          วิเคราะห์ข้อมูล วางแผนทางการตลาด การส่งข้อมูล ข่าวสารต่างๆ
          การประชาสัมพันธ์ และการบริการอื่นๆ หรือตามวัตถุประสงค์ของสัญญาฉบับนี้
          ด้วยความสมัครใจ ปราศจากการบังคับหรือชักจูง จากผู้จัดงานทั้งสิ้น
          <br />
          28.กรณีที่ผู้แสดงสินค้าประสงค์จะขอถอนความยินยอม
          ผู้แสดงสินค้าทราบว่าการถอนความยินยอมจะมีผลทำให้
          ผู้แสดงสินค้าอาจได้รับความไม่สะดวกในด้านการบริการหรืออื่นๆ
          และการถอนความยินยอมดังกล่าว
          ไม่มีผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลที่ได้ดำเนินการเสร็จสิ้นไปแล้วก่อนการถอนความยินยอม
          <br />
          29.ข้อความในสัญญาบริการพื้นที่แสดงสินค้าที่ปรากฏข้างต้นทุกข้อเป็นสาระสำคัญของสัญญา
          หากผู้แสดงสินค้าประพฤติผิดสัญญาข้อหนึ่งข้อใด หรือหลายข้อ
          ผู้จัดงานมีสิทธิบอกเลิกสัญญานี้โดยให้มีผลในทันที
          โดยมิพักต้องบอกกล่าวก่อน
          <br />
          30.บรรดาหนังสือ
          หรือคำบอกกล่าวใดๆที่คู่สัญญามีไปถึงหรือส่งมายังคู่สัญญาอีกฝ่ายหนึ่งตามที่อยู่ที่ปรากฏในสัญญานี้แล้วโดยการส่งทางไปรษณีย์ลงทะเบียนตอบรับ
          จดหมายอิเล็กทรอนิกส์หรือส่งโดยพนักงานของคู่สัญญาฝ่ายหนึ่งโดยมีหลักฐาน
          การรับจากคู่สัญญาอีกฝ่ายหนึ่ง หรือลูกจ้าง
          หรือบริวารของคู่สัญญาฝ่ายนั้นแล้ว ให้ถือว่าเป็นการส่งหนังสือ
          หรือคำบอกกล่าวโดยชอบแล้วตั้งแต่วันที่ได้ส่งถึง หรือเซ็นรับดังกล่าว
        </p>
        <p>
          สัญญานี้ทําขึ้นเป็นสองฉบับมีข้อความตรงกันคู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อความโดยตลอดแล้วเห็นว่าถูกตอ้งตรงตามความประสงค์ทุกประการจึงลงลายมือชื่อไว้เป็นหลักฐานและต่อหน้าพยาน
        </p>
      </div>
    </div>
  );
}
